import React from "react";
import { Stack, Box } from "@chakra-ui/react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { clone } from "lodash";

import AppBar from "../ui/mainPage/AppBar";
import Footer from "../ui/mainPage/Footer";

const Layout = (props) => {
  const {
    children,
    refs,
    main,
    metaTags,
    title = "microPublication - Get Your Data Out, Be Cited",
  } = props;

  const data = useStaticQuery(graphql`
    query {
      menuData: allIndex {
        nodes {
          internal {
            content
          }
        }
      }
      taxonomies: micropubApp {
        species {
          value
          label
        }
        categories {
          value
          label
        }
        submissionTypes {
          value
          label
        }
      }
      articles: micropubApp {
        publishedManuscripts(role: "editor") {
          species
        }
      }
    }
  `);

  const lunrIndex = JSON.parse(data.menuData.nodes[0].internal.content);
  const speciesOptions = data.taxonomies.species
    .map((s) => {
      const species = clone(s);
      species.articleCount = data.articles.publishedManuscripts.filter(
        (article) =>
          article.species &&
          article.species.some(
            (articleSpecies) =>
              articleSpecies.toLowerCase() === s.value ||
              articleSpecies === s.label
          )
      ).length;
      return species;
    })
    .filter((s) => s.articleCount > 1);

  const categoryOptions = data.taxonomies.categories;
  const submissionTypeOptions = data.taxonomies.submissionTypes;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {metaTags &&
          metaTags.map((meta) => (
            <meta
              name={meta.name}
              content={meta.content}
              key={`meta-${meta.name}`}
            />
          ))}
      </Helmet>
      <Stack>
        <AppBar
          lunrIndex={lunrIndex}
          refs={refs}
          main={main}
          speciesOptions={speciesOptions}
          categoryOptions={categoryOptions}
          submissionTypeOptions={submissionTypeOptions}
        />
        {!main && <Box paddingTop="100px">{children}</Box>}
        {main && children}
        <Footer />
      </Stack>
    </>
  );
};

export default Layout;
