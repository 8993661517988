import React from "react";
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  PopoverTrigger,
  Popover,
  Button,
  PopoverBody,
  PopoverContent,
  PopoverArrow,
  Stack,
  DrawerBody,
  SimpleGrid,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "gatsby";

const SideMenu = (props) => {
  const {
    setVisible,
    visible,
    speciesOptions,
    categoryOptions,
    submissionTypeOptions,
  } = props;

  return (
    <Drawer placement="left" isOpen={visible} onClose={setVisible}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerBody>
            <Stack>
              <Button as={Link} to="/about" variant="outline">
                About
              </Button>
              <Popover trigger="hover" placement="right">
                <PopoverTrigger>
                  <Button
                    as={Link}
                    to="/about/editorial-policies"
                    variant="outline"
                    rightIcon={<ChevronRightIcon />}
                  >
                    Editorial Information
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <Stack>
                      <Button
                        as={Link}
                        to="/about/editorial-policies/editorial-board-and-staff"
                        variant="outline"
                        isFullWidth
                      >
                        Editorial Staff
                      </Button>
                      <Button
                        as={Link}
                        to="/about/editorial-policies/micropublication-biology-editorial-board"
                        variant="outline"
                        isFullWidth
                      >
                        Editorial Board
                      </Button>
                      <Button
                        as={Link}
                        to="/about/editorial-policies/publishing-information"
                        variant="outline"
                        isFullWidth
                      >
                        Publishing Information
                      </Button>
                      <Button
                        as={Link}
                        to="/data-sharing-policy"
                        variant="outline"
                        isFullWidth
                      >
                        Data Sharing Policy
                      </Button>
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              <Popover trigger="hover" placement="right">
                <PopoverTrigger>
                  <Button
                    as={Link}
                    to="/about/for-authors"
                    variant="outline"
                    rightIcon={<ChevronRightIcon />}
                  >
                    For Authors
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <Stack>
                      <Button
                        as={Link}
                        to="/about/for-authors#signing-up-and-signing-on"
                        variant="outline"
                        isFullWidth
                      >
                        Preparation And Submission Of A Manuscript
                      </Button>
                      <Button
                        as={Link}
                        to="/about/for-authors#peer-review-process"
                        variant="outline"
                        isFullWidth
                      >
                        Peer Review Process
                      </Button>
                      <Button
                        as={Link}
                        to="/about/for-authors#following-publication"
                        variant="outline"
                        isFullWidth
                      >
                        Following Acceptance
                      </Button>
                      <Button
                        as={Link}
                        to="/about/for-authors#appeals"
                        variant="outline"
                        isFullWidth
                      >
                        Appeals
                      </Button>
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              <Button as={Link} to="/about/for-reviewers" variant="outline">
                For Reviewers
              </Button>
              <Button as={Link} to="/about#why-micropublish?" variant="outline">
                Why micropublish?
              </Button>
              <Button as={Link} to="/submit-new-article" variant="outline">
                Submit a microPublication
              </Button>
              <Button as={Link} to="/journals/biology" variant="outline">
                All microPublications
              </Button>
              <Button
                as={Link}
                to="/journals/biology/categories/integrations"
                variant="outline"
              >
                Integrations
              </Button>
              <Popover trigger="hover" placement="right">
                <PopoverTrigger>
                  <Button
                    as={Link}
                    to="/journals/biology/species"
                    variant="outline"
                    rightIcon={<ChevronRightIcon />}
                  >
                    Species
                  </Button>
                </PopoverTrigger>
                <PopoverContent p={0} width="1000px">
                  <PopoverArrow />
                  <PopoverBody>
                    <SimpleGrid columns={4}>
                      {speciesOptions
                        //    .filter((s) => s.articleCount > 1)
                        .map((species) => (
                          <Button
                            as={Link}
                            to={`/journals/biology/species/${species.value
                              .replace(" ", "-")
                              .replace(".", "")}`}
                            variant="outline"
                            key={`species-${species.value}`}
                          >
                            {species.label}
                          </Button>
                        ))}
                      <Button
                        as={Link}
                        to="/journals/biology/species"
                        variant="outline"
                        key="more"
                      >
                        more...
                      </Button>
                    </SimpleGrid>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              <Popover trigger="hover" placement="right">
                <PopoverTrigger>
                  <Button
                    as={Link}
                    to="/journals/biology/categories"
                    variant="outline"
                    rightIcon={<ChevronRightIcon />}
                  >
                    Categories
                  </Button>
                </PopoverTrigger>
                <PopoverContent p={0}>
                  <PopoverArrow />
                  <PopoverBody>
                    <Stack>
                      {categoryOptions.map((category) => (
                        <Button
                          as={Link}
                          to={`/journals/biology/categories/${category.value}`}
                          variant="outline"
                          isFullWidth
                          key={`category-${category.value}`}
                        >
                          {category.label}
                        </Button>
                      ))}
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              <Popover trigger="hover" placement="right">
                <PopoverTrigger>
                  <Button
                    as={Link}
                    to="/journals/biology/types"
                    variant="outline"
                    rightIcon={<ChevronRightIcon />}
                  >
                    Article Types
                  </Button>
                </PopoverTrigger>
                <PopoverContent p={0}>
                  <PopoverArrow />
                  <PopoverBody>
                    <Stack>
                      {submissionTypeOptions.map((submissionType) => (
                        <Button
                          as={Link}
                          to={`/journals/biology/types/${submissionType.value}`}
                          variant="outline"
                          isFullWidth
                          key={`type-${submissionType.value}`}
                        >
                          {submissionType.label}
                        </Button>
                      ))}
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              <Button as={Link} to="/contact-us" variant="outline">
                Contact
              </Button>
              <Button as={Link} to="/archives" variant="outline">
                Archives
              </Button>
              <Button as={Link} to="/faqs" variant="outline">
                FAQs
              </Button>
              <Button as={Link} to="/newsletter" variant="outline">
                Newsletter
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default SideMenu;
